import * as React from 'react';

export const ListItemDivider = () => {
    return (
        <div
            style={{ width: 'calc(100% - 2rem)' }}
            className="mx-auto border-b border-gray-50 last-of-type:hidden"
        />
    );
};
