import * as React from 'react';
import { cn } from '~/common/utils/styles';
import { Avatar } from '~/cds/Avatar';
import { Typography } from '~/cds/Typography';
import { Label } from '~/cds/Label';
import { ListItemDivider } from '~/cds/ListItem/components';

export const ListItem = ({
    className,
    children,
    disabled,
    rank,
    avatarUrl,
    label,
    paragraph,
    data,
    metadata,
    ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
    children?: React.ReactNode;
    disabled?: boolean;
    rank?: string;
    avatarUrl?: string;
    label?: string;
    paragraph?: string;
    data?: string | React.ReactNode;
    metadata?: string;
}) => {
    return (
        <>
            <div
                className={cn(
                    'isolate flex w-full cursor-pointer items-center gap-4 rounded-sm bg-white p-4 hover:bg-gray-25 active:bg-gray-50',
                    className,
                    disabled &&
                        'pointer-events-none cursor-not-allowed opacity-50'
                )}
                {...rest}
            >
                <div className="flex w-full items-center justify-between gap-4">
                    {children ? (
                        children
                    ) : (
                        <>
                            <div className="flex items-center gap-4">
                                {rank && <Label>{rank}</Label>}
                                <Avatar variant="md" src={avatarUrl} />
                                <div>
                                    <Label>{label}</Label>
                                    {paragraph && (
                                        <Typography
                                            className="text-gray-500"
                                            intent="body"
                                            size="sm"
                                            as="p"
                                        >
                                            {paragraph}
                                        </Typography>
                                    )}
                                </div>
                            </div>
                            <div className="text-right">
                                {data && <Label>{data}</Label>}
                                {metadata && (
                                    <Typography
                                        className="text-gray-500"
                                        intent="body"
                                        size="sm"
                                        as="p"
                                    >
                                        {metadata}
                                    </Typography>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

ListItem.Divider = ListItemDivider;
